<template>
  <div class="container">
    <table-page dataName="prizeList"
                ref="table"
                :params="{activity_id:$route.query.id}"
                :request="questionnairePrizes">
      <template slot="button">
        <el-button type="primary"
                   v-permission="['ADMIN']"
                   @click="addPrize()">添加奖品</el-button>
      </template>
      <el-table-column label="奖品名称">
        <template slot-scope="scope">
          <span v-if="scope.row.type === 'ENVELOPE'">
            {{ scope.row.name || scope.row.money+'元现金红包'}}
          </span>
          <span v-else>{{ scope.row.name}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="quantity"
                       label="奖品数量">
      </el-table-column>
      <el-table-column label="奖品类型">
        <template slot-scope="scope">
          {{ scope.row.type | prizeType}}
        </template>
      </el-table-column>
      <el-table-column prop="address"
                       label="奖品图片">
        <template slot-scope="scope">
          <TableImg :url="scope.row.img ? scope.row.img.url :''" />
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="text"
                     @click="$refs.setGood.showDialog(scope,$route.query.id)">编辑</el-button>
          <el-button type="text"
                     @click="delPrize(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </table-page>
    <SetGood ref="setGood"
             @on-success="$refs.table.fetch()" />
  </div>
</template>

<script>
import TableImg from '@/components/TableImg'
import SetGood from './components/SetGood'
import TablePage from '@/components/TablePage'
import { questionnairePrizes, questionnaireDelPrize } from '@/api/market'
export default {
  data () {
    return {
      questionnairePrizes
    }
  },
  components: {
    TablePage,
    SetGood,
    TableImg
  },
  filters: {
    prizeType (val) {
      if (val) {
        switch (val) {
          case 'ENVELOPE':
            return '红包'
          case 'COUPON':
            return '代金券'
          case 'KIND':
            return '邮寄实物'
          case 'VERIFY':
            return '网点核销'
          case 'EXPENSES':
            return '话费充值'
          case 'CARD_KEY':
            return '卡密'
          case 'RECHARGE':
            return '直充会员'
          default:
            return ''
        }
      } else {
        return ''
      }
    }
  },
  methods: {
    addPrize () {
      if (this.$refs.table.tableData.length > 7) {
        this.$message.error('最多添加8个奖品')
      } else {
        this.$refs.setGood.showDialog(null, this.$route.query.id)
      }
    },
    delPrize (id) {
      this.$confirm('确认删除', {
        type: 'warning'
      }).then(async () => {
        const res = await questionnaireDelPrize({
          id: id
        })
        if (res.meta.code === 0) {
          this.$notify({
            title: '成功',
            message: '删除成功',
            type: 'success'
          })
          this.$refs.table.fetch()
        }
      })
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
</style>
